import { defineStore } from "pinia";

export const usePageStore = defineStore("page", {
  state: () => ({
    page: {},
  }),

  getters: {
    header_theme: (state) => {
      if (!state.page?.content) return "dark";
      if (state.page?.full_slug?.startsWith("resources/articles")) return "light";
      if (state.page?.full_slug?.startsWith("resources/guides")) return "light";
      if (state.page?.full_slug?.startsWith("resources/templates")) return "light";
      return state.page?.content?.header_theme || "dark";
    },
    footer_theme: (state) => {
      return state.page?.content?.footer_theme || "dark";
    },
  },
});
